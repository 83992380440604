#dashboardDiv {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 0.2rem;
}

#rangeFilterDiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 1rem;
}

#rangeFilterDiv select {
  max-width: max-content;
  line-height: 1.5;
}
