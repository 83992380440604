#brand {
  display: flex;
  height: 60px;
  padding: 10px;
}

#sideNavContainer {
  padding: 10px;
  margin: 1.1em auto;
}

.sidebarNavItem {
  font-size: 1em !important;
  color: black;
  overflow-wrap: break-word;
  text-align: start;
  display: flex;
  justify-content: start;
  text-decoration: none;
  padding: 10px 0;
}

.sidebarNavItem.active {
  opacity: 0.8;
  background-color: rgb(221, 221, 221);
}

.sidebarNavItem:hover {
  color: #e44b1a;
}
.sidenavToggler {
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.collapsedSidebar .sidenavToggler {
  transform: rotateY(-90deg);
  position: absolute;
}
.expandedSidebar .sidenavToggler {
  display: inline !important;
  cursor: pointer;
  padding-right: 5px;
  transition: all 0.3s;
}

#sidebar {
  transition: min-width 0.3s;
  -webkit-transition: min-width 0.3s;
  -moz-transition: min-width 0.3s;
  position: fixed;
  overflow: hidden auto;
  z-index: 100;
  height: 100%;
  box-shadow: 1px 1px 2px 1px rgba(47, 47, 47, 0.2);
  background: white;
  line-height: 1.5;
}

#sidebar.collapsedSidebar {
  min-width: 64px;
}

#sidebar.expandedSidebar {
  min-width: 200px;
}

.sidebarNavItemText {
  transition: all 0.09s;
}

.expandedSidebar .sidebarNavItemHiddenOnCollapse {
  transition: all 0.05s;
}

.collapsedSidebar .sidebarNavItemText {
  transform: rotateY(-90deg);
  position: absolute;
}

.collapsedSidebar .sidebarNavItemHiddenOnCollapse {
  transform: rotateY(-90deg);
  position: absolute;
  transition: all 0s;
}

.expandedSidebar .sidebarNavItemText {
  display: inline !important;
  cursor: pointer;
  padding-right: 5px;
}

.collapsedSidebar .sidebarNavItemIcon {
  margin: auto;
  padding: 0 !important;
}

.expandedSidebar .sidebarNavItemIcon {
  display: inline !important;
  padding: 0 10px;
}

.collapsedSidebar #sideNavOrganisationTitle {
  display: none;
}

#sideNavOrganisationTitle {
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #e44b1b;
  color: white;
  font-size: 1rem;
}
