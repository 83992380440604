#app {
  transition: all 0.3s ease-in-out;
  margin-left: 80px;
}

@media screen and (min-width: 760px) {
  #app.leftSidebarExpandedMargin {
    margin-left: 260px;
  }
}
