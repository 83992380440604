.statHeading {
  font-size: 1rem;
  font-weight: 400;
}

.statWithIconDiv {
  display: flex;
  justify-content: start;
  padding: 0.5rem;
}

.stat {
  font-size: 30px;
  padding-left: 1rem;
  font-weight: 250;
}
