.ampTable {
  width: 100%;
  margin-bottom: 0.1rem;
  text-align: center;
  line-height: 1;
}

.ampTable th {
  background-color: #e44b1b;
  color: white;
  padding: 0.5rem;
}

.ampTable th,
.ampTable td {
  padding: 0.5rem;
  border: 1px solid white;
}

.ampTable td {
  border: 1px solid white;
}

.ampTable > tbody > :nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.ampTable.nowrap td,
.ampTable.nowrap th {
  white-space: nowrap;
  padding: 8px 10px;
  text-align: start;
  text-indent: inherit;
}

.ampTable tr:hover {
  background-color: rgb(215, 215, 205);
}
