.centerAbsoloute {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerInline {
  display: flex;
  justify-content: center;
}
