#editOrganisationForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 200px) minmax(200px, 1fr));
  gap: 0.2rem;
}

#editOrganisationForm .buttonDiv {
  grid-column: span 4;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-align: center;
  margin-top: 2rem;
}

#editOrganisationForm button {
  width: 200px;
}
